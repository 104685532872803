import React, { StrictMode, useState, useEffect, useCallback } from "react"
import './App.css'
import AzureAD, { IAzureADFunctionProps, AuthenticationState } from "react-aad-msal"
import { msalAuthProvider } from "./Provider/msalAuthProvider"
import { context } from "./Provider/context"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"

const App: React.FC = () => {  
  const [url, setUrl] = useState("")

  const generateOnlineMeetingUrl = (event: any) => {
    fetchData()
  }
  
  const fetchData = useCallback(async () => {
    const token = await msalAuthProvider.getAccessToken();
 
    fetch(`${process.env.REACT_APP_API_BASEURL}/staff/selfservice/GetOnlineMeetingUrl`, {
      method: 'get',
      headers: new Headers(
        {
          'Authorization': 'Bearer ' + token.accessToken,
          'Content-Type': 'text/plain; charset=utf-8'
        })
    })
      .then(function (body) { return body.text() })
      .then(function (data) {        
        setUrl(data)
      })
  }, []);  

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return <StrictMode>
    <AzureAD provider={msalAuthProvider} forceLogin={true}>
      {({ login, logout, authenticationState, accountInfo }: IAzureADFunctionProps) => {
        switch (authenticationState) {
          case AuthenticationState.Unauthenticated:
            return <div className="App">
              <p>
                <span>Click button to login</span>
                <button onClick={login}>Login</button>
              </p>
            </div>
          case AuthenticationState.InProgress:
            return <p>Authenticating...</p>
          case AuthenticationState.Authenticated:
            if (accountInfo)
              return <context.Provider value={{ msalAuthProvider, accountInfo }}>
                <div className="App">
                  <div id="header" className="row">
                    <div id="banner" className="container">
                      <a href="http://www.fh-krems.ac.at" id="logo" target="_blank"
                        rel="noopener noreferrer" title="Startseite">
                        <img src="https://www.fh-krems.ac.at/fileadmin/bsdist/theme/img/imc-fh-krems_logo_weiss.png"
                          alt="IMC Fachhochschule Krems GesmbH - University of Applied Sciences Austria" />
                      </a>                      
                    </div>
                  </div>
                  <div id="body" className="container">
                    <p>Account: <b>{accountInfo.account.name} ({accountInfo.account.userName.toLowerCase()})</b></p>
                    {url !== "" && <>
                      <p>This is your livestream link:</p>
                      <p id="link">
                        <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                      </p>
                      <p>Click the url to enter your livestream. Copy and send it to someone else.</p>
                      <p>Close this window and refresh the eDesktop to show the livestream link again.</p>
                    </>}
                    {url === "" && <>
                      <Loader type="ThreeDots" color="#335784" height={80} width={80} />                       
                    </>}
                    <button onClick={logout}>Logout</button>
                  </div>
                </div>
              </context.Provider>
        }
      }}
    </AzureAD>
  </StrictMode>
}

export default App
